import { ComponentPropsWithoutRef } from 'react';

import { NewIcon } from 'design-system';
import { isEmpty } from 'lodash-es';
import { cn } from 'tailwind-config';

export const Select = ({
  options,
  bgColor,
  ...props
}: ComponentPropsWithoutRef<'select'> & {
  options: { value: string; label: string }[];
  bgColor?: string;
}) => {
  return (
    <div className="relative">
      <select
        className={cn(
          bgColor ? bgColor : 'bg-new-gray-50',
          ' w-full appearance-none overflow-hidden text-ellipsis rounded-[12px] bg-[inherit] px-16 py-12 pr-40 focus-visible:outline-none',
          options.length === 0 || isEmpty(props.value) || props.value === options[0].value
            ? 'text-new-Body1-medium text-new-gray-400'
            : 'text-new-Body1-bold',
        )}
        {...props}
      >
        {options.map((option) => {
          return (
            <option
              key={option.value}
              value={option.value}
              className="data-[state=checked]:text-new-Body1-bold text-new-Body1-medium flex flex-1 cursor-pointer rounded-[8px] !border-none p-8 focus-visible:outline-none data-[state=checked]:bg-[#FFFDCC]"
            >
              {option.value}
            </option>
          );
        })}
      </select>
      <NewIcon
        icon="arrowdown-outlined"
        size={24}
        className="fill-new-gray-400 absolute-items-center pointer-events-none right-12"
      />
    </div>
  );
};
