import { useState } from 'react';

import { LogEvent } from '@analytics';
import { useQuery } from '@tanstack/react-query';
import { NewBoxButton, Spacer, Toaster, useToast } from 'design-system';
import { useSetAtom } from 'jotai';
import { isEmpty } from 'lodash-es';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { cn } from 'tailwind-config';

import { isGlobalLinkFromAcademy } from '@pages/academy/[id]/application';
import { Select } from '@templates/Training/TrainingReservation/screens/BasicInformation/components/Select';

import { WEB_QUERY_KEY } from '@constants';

import { globalBlockingScreenAtom } from '../Layout';

const useGetSigungu = () => {
  return useQuery({
    queryKey: [WEB_QUERY_KEY.SIGUNGU_LOCATION],
    queryFn: async () => {
      const res = await fetch('/sigungudong-location.json');

      return (
        (await res.json()) as {
          data: { sido: string; sigungu: string; dong: string; lat: number; lng: number }[];
        }
      ).data;
    },
  });
};

const fields = ['sido', 'sigungu', 'dong'];

export const SigunguSelectButton = ({ fullWidth }: { fullWidth?: boolean }) => {
  const { openToast } = useToast();

  const { data: locationData = [] } = useGetSigungu();

  const router = useRouter();

  const [reservationLocation, setReservationLocation] = useState({
    sido: '',
    sigungu: '',
    dong: '',
    lat: 0,
    lng: 0,
  });

  // 옵션 필터링 함수
  const filterOptions = {
    sido: () => [
      { value: '시, 도', label: '시, 도' },
      ...Array.from(new Set(locationData.map((loc) => loc.sido))).map((sido) => ({
        value: sido,
        label: sido,
      })),
    ],
    sigungu: () => [
      { value: '시, 군, 구', label: '시, 군, 구' },
      ...Array.from(
        new Set(
          locationData
            .filter((loc) => loc.sido === reservationLocation.sido)
            .map((loc) => loc.sigungu),
        ),
      ).map((sigungu) => ({
        value: sigungu,
        label: sigungu,
      })),
    ],
    dong: () => [
      { value: '동, 읍, 면, 리', label: '동, 읍, 면, 리' },
      ...locationData
        .filter(
          (loc) =>
            loc.sido === reservationLocation.sido && loc.sigungu === reservationLocation.sigungu,
        )
        .map((loc) => ({
          value: loc.dong,
          label: loc.dong,
        })),
    ],
  };

  return (
    <>
      <div className={cn(fullWidth && 'w-full', ' grid grid-cols-2 gap-8 ')}>
        {fields.map((field, index) => (
          <div
            key={field}
            className={cn(
              '',
              index === fields.length - 1 ? 'col-span-2' : fullWidth ? 'w-full' : 'w-[144px]',
            )}
          >
            <Select
              bgColor="bg-new-white"
              onClick={() => {
                if (field === 'sigungu' && isEmpty(reservationLocation.sido)) {
                  openToast({
                    title: '시, 도를 먼저 선택해주세요.',
                  });
                  return;
                }

                if (field === 'dong' && isEmpty(reservationLocation.sigungu)) {
                  openToast({
                    title: '시, 군, 구를 먼저 선택해주세요.',
                  });
                  return;
                }
              }}
              options={filterOptions[field as keyof typeof filterOptions]()}
              onChange={(e) => {
                setReservationLocation((prev) => {
                  return {
                    ...prev,
                    ...{ [field]: e.target.value },
                    ...(field === 'sido' ? { sigungu: '', dong: '' } : {}),
                    ...(field === 'sigungu' ? { dong: '' } : {}),
                    ...(field === 'dong'
                      ? locationData.find(
                          (loc) =>
                            loc.sido === prev.sido &&
                            loc.sigungu === prev.sigungu &&
                            loc.dong === e.target.value,
                        )
                      : { lat: 0, lng: 0 }),
                  };
                });
              }}
              value={
                filterOptions[field as keyof typeof filterOptions]().find(
                  (option) =>
                    option.value === reservationLocation[field as keyof typeof filterOptions],
                )?.label
              }
            />
          </div>
        ))}
      </div>
      <Spacer className="h-24" />
      <NewBoxButton
        label={'내 근처 학원 찾기'}
        styles="filled-yellow"
        fill
        disabled={isEmpty(reservationLocation.dong)}
        onClick={async () => {
          LogEvent.활성화.blogToExplore('검색버튼클릭');
          await router.push({
            pathname: '/explore',
            query: {
              lat: reservationLocation.lat,
              lng: reservationLocation.lng,
            },
          });
        }}
      />
    </>
  );
};

export const ActionButton = () => {
  const setGlobalBlockingScreen = useSetAtom(globalBlockingScreenAtom);
  const { openToast } = useToast();

  const { data: locationData = [] } = useGetSigungu();

  const router = useRouter();

  const [reservationLocation, setReservationLocation] = useState({
    sido: '',
    sigungu: '',
    dong: '',
    lat: 0,
    lng: 0,
  });

  // 옵션 필터링 함수
  const filterOptions = {
    sido: () => [
      { value: '시, 도', label: '시, 도' },
      ...Array.from(new Set(locationData.map((loc) => loc.sido))).map((sido) => ({
        value: sido,
        label: sido,
      })),
    ],
    sigungu: () => [
      { value: '시, 군, 구', label: '시, 군, 구' },
      ...Array.from(
        new Set(
          locationData
            .filter((loc) => loc.sido === reservationLocation.sido)
            .map((loc) => loc.sigungu),
        ),
      ).map((sigungu) => ({
        value: sigungu,
        label: sigungu,
      })),
    ],
    dong: () => [
      { value: '동, 읍, 면, 리', label: '동, 읍, 면, 리' },
      ...locationData
        .filter(
          (loc) =>
            loc.sido === reservationLocation.sido && loc.sigungu === reservationLocation.sigungu,
        )
        .map((loc) => ({
          value: loc.dong,
          label: loc.dong,
        })),
    ],
  };

  return (
    <>
      <div
        className={cn(
          'border-new-DTYellow-400 grid grid-cols-2 gap-8 rounded-[16px] border-2 bg-white px-16 py-24 ',
        )}
      >
        {fields.map((field, index) => (
          <div
            key={field}
            className={cn('', index === fields.length - 1 ? 'col-span-2' : 'w-[144px]')}
          >
            <Select
              onClick={() => {
                if (field === 'sigungu' && isEmpty(reservationLocation.sido)) {
                  openToast({
                    title: '시, 도를 먼저 선택해주세요.',
                  });
                  return;
                }

                if (field === 'dong' && isEmpty(reservationLocation.sigungu)) {
                  openToast({
                    title: '시, 군, 구를 먼저 선택해주세요.',
                  });
                  return;
                }

                LogEvent.활성화.pcWebToApp('주소 클릭');
              }}
              options={filterOptions[field as keyof typeof filterOptions]()}
              onChange={(e) => {
                setReservationLocation((prev) => {
                  return {
                    ...prev,
                    ...{ [field]: e.target.value },
                    ...(field === 'sido' ? { sigungu: '', dong: '' } : {}),
                    ...(field === 'sigungu' ? { dong: '' } : {}),
                    ...(field === 'dong'
                      ? locationData.find(
                          (loc) =>
                            loc.sido === prev.sido &&
                            loc.sigungu === prev.sigungu &&
                            loc.dong === e.target.value,
                        )
                      : { lat: 0, lng: 0 }),
                  };
                });
              }}
              value={
                filterOptions[field as keyof typeof filterOptions]().find(
                  (option) =>
                    option.value === reservationLocation[field as keyof typeof filterOptions],
                )?.label
              }
            />
          </div>
        ))}
      </div>
      <Spacer className="h-24" />
      <NewBoxButton
        label={'검색'}
        styles="filled-black"
        fill
        disabled={isEmpty(reservationLocation.dong)}
        onClick={async () => {
          LogEvent.활성화.pcWebToApp('버튼 클릭');

          setGlobalBlockingScreen(true);
          await router.push({
            pathname: '/explore',
            query: {
              lat: reservationLocation.lat,
              lng: reservationLocation.lng,
            },
          });

          setTimeout(() => {
            setGlobalBlockingScreen(false);
          }, 2500);
        }}
      />
    </>
  );
};

export const PCWebToApp = ({ className }: { className?: string }) => {
  return (
    <div
      className={cn(
        'relative flex h-[100dvh] flex-col justify-center max-[1024px]:hidden',
        isGlobalLinkFromAcademy && 'hidden',
        className,
      )}
      style={{
        transform: 'translateZ(0)',
      }}
    >
      <Toaster>
        <p className="text-new-Title2 whitespace-pre">
          앱으로 할인 받고
          <br />
          운전학원 예약해보세요!
        </p>
        <Spacer className="h-16" />
        <section className="flex items-end gap-16">
          <Image
            src="/pc-web-to-app/qr.png"
            width={124}
            height={124}
            alt="QR 코드 이미지"
            className=""
          />
          <div className="flex items-center gap-8">
            <Link href="https://apps.apple.com/us/app/%EC%9A%B4%EC%A0%84%EC%84%A0%EC%83%9D-%EC%9A%B4%EC%A0%84%ED%95%99%EC%9B%90-%EC%B5%9C%EC%A0%80%EA%B0%80-%EC%98%88%EC%95%BD/id6444027313">
              <Image
                src="/pc-web-to-app/apple-icon.png"
                width={40}
                height={40}
                alt="앱스토어 이미지"
              />
            </Link>
            <Link href="https://play.google.com/store/apps/details?id=com.drivingteacher">
              <Image
                src="/pc-web-to-app/playstore-icon.png"
                width={40}
                height={40}
                alt="플레이스토어 이미지"
              />
            </Link>
          </div>
        </section>
        <Spacer className="h-40" />

        <section className="bg-new-gray-50 flex flex-col items-center rounded-[16px] px-16 shadow-[0px_10px_30px_0px_#18181829]">
          <Spacer className="h-32" />
          <Image src="/pc-web-to-app/text-logo.png" width={100} height={27.5} alt="운전선생 로고" />
          <Spacer className="h-38" />
          <p className="text-new-Sub-Title whitespace-nowrap text-center">
            지금 바로
            <br />
            예약 가능한 학원을 찾아보세요!
          </p>
          <Spacer className="h-24" />
          <ActionButton />
          <Spacer className="h-40" />
          <div className="flex w-full items-center gap-16 rounded-[16px] bg-white px-[16px] py-8">
            <Image
              src="/image-money.png"
              width={96}
              height={96}
              className="h-[48px] w-[48px]"
              alt="돈 이미지"
            />
            <p className="text-new-Body1-medium">
              더이상
              <strong className="text-new-Body1-bold"> 직접 가서 예약</strong>
              하지 마세요!
            </p>
          </div>
          <Spacer className="h-16" />
          <div className="flex w-full items-center gap-16 rounded-[16px] bg-white px-[16px] py-8">
            <Image
              src="/need-login.png"
              width={96}
              height={96}
              className="h-[48px] w-[48px]"
              alt="돈 이미지"
            />
            <p className="text-new-Body1-medium">
              운전학원
              <strong className="text-new-Body1-bold"> 최저가로 예약</strong>
              하세요!
            </p>
          </div>
          <Spacer className="h-32" />
        </section>
      </Toaster>
    </div>
  );
};
